import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MiniSwap from "./miniswap-crypto";
import Home from "./home";



function App() {
  return (  
  <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/miniswap-crypto" element={<MiniSwap />} />

      </Routes>
    </Router>
  </>

  )
}

export default App;
