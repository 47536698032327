import React, { useState } from "react";
import backgroundImage from '../../assets/background.png'
import mainlogo from '../../assets/miniswap.svg'
import Profile3 from '../Profile3'

const Hero13 = (
  {
    title,
    h1,
    paragraph,
    button,
    explore,
    title_card,
    paragraph_card,
    button1_card,
    button1_href,
    button2_card,
    button2_href,
  }
) => {
  return (
    <>
      <Navbar />

      {/* <!-- ====== Hero Section Start --> */}
      <div
        className="relative z-10 bg-cover bg-center bg-no-repeat pt-[120px] pb-20 md:pt-[150px]"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="bg-light absolute top-0 left-0 -z-10 h-full w-full bg-opacity-[25%]"></div>
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/2">
              <div className="mb-16 max-w-[500px] lg:mb-0">
                <span className="mb-4 block text-base font-bold text-white sm:text-lg md:text-xl">
                  {title}
                </span>
                <h1 className="mb-5 text-3xl font-bold leading-tight text-white sm:text-4xl sm:leading-tight md:text-5xl md:leading-tight lg:text-[42px] lg:leading-tight xl:text-5xl xl:leading-tight">
                  {h1}
                </h1>
                <p className="font-semibold mb-10 text-base leading-relaxed text-white md:text-lg md:leading-relaxed">
                  {paragraph}
                </p>
                <ul className="flex items-center">
                  <li>
                    <a
                      href="/#"
                      className="bg-primary mb-2 inline-flex items-center justify-center rounded-md py-3 px-6 text-center text-base font-medium text-white hover:bg-opacity-90 sm:py-4 sm:px-9"
                    >
                      {button}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      className="mb-2 inline-flex items-center py-3 px-6 text-base font-medium text-white hover:opacity-70 sm:py-4 sm:px-9"
                    >
                      {explore}
                      <span className="ml-2">
                        <svg
                          width="20"
                          height="8"
                          viewBox="0 0 20 8"
                          className="fill-current"
                        >
                          <path d="M19.2188 2.90632L17.0625 0.343819C16.875 0.125069 16.5312 0.0938193 16.2812 0.281319C16.0625 0.468819 16.0312 0.812569 16.2188 1.06257L18.25 3.46882H0.9375C0.625 3.46882 0.375 3.71882 0.375 4.03132C0.375 4.34382 0.625 4.59382 0.9375 4.59382H18.25L16.2188 7.00007C16.0312 7.21882 16.0625 7.56257 16.2812 7.78132C16.375 7.87507 16.5 7.90632 16.625 7.90632C16.7812 7.90632 16.9375 7.84382 17.0312 7.71882L19.1875 5.15632C19.75 4.46882 19.75 3.53132 19.2188 2.90632Z" />
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="w-full px-4 lg:w-1/2">
              <div className="mx-auto max-w-[460px] rounded-lg bg-white py-10 px-6 sm:px-10">
              <Profile3
                  title_card={title_card}
                  paragraph_card={paragraph_card}
                  button1_card={button1_card}
                  button1_href={button1_href}
                  button2_card={button2_card}
                  button2_href={button2_href}

              />
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* <!-- ====== Hero Section End -->    */}
    </>
  );
};

export default Hero13;


const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* <!-- ====== Navbar Section Start --> */}
      <header className="absolute left-0 top-0 z-50 w-full">
        <div className="container mx-auto">
          <div className="relative -mx-4 flex items-center justify-between">
            <div className="w-60 max-w-full px-4">
              <a href="/#" className="block w-full py-5">
                <img
                  src={mainlogo}
                  alt="CarbonHood"
                  className="w-full"
                />
              </a>
            </div>
            <div className="flex w-full items-center justify-between px-4">
              <div>
                <button
                  onClick={() => setOpen(!open)}
                  className={` ${
                    open && "navbarTogglerActive"
                  } ring-primary absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] focus:ring-2 lg:hidden`}
                >
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                  <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                </button>
                <nav
                  className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 px-6 shadow lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:shadow-none ${
                    !open && "hidden"
                  } `}
                >
                  <ul className="block lg:flex">
                    <ListItem NavLink="/">Home</ListItem>
                    <ListItem NavLink="/knowledge-centre">Knowledge Centre</ListItem>
                  </ul>
                </nav>
              </div>
              <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
                <a
                  href="https://app.miniswap.xyz/"
                  className="text-primary rounded-lg bg-white py-3 px-7 text-base font-medium hover:bg-opacity-90"
                >
                  Launch App
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ====== Navbar Section End -->    */}
    </>
  );
};

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="text-white hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex lg:text-white"
        >
          {children}
        </a>
      </li>
    </>
  );
};
