import React from "react";

const Footer7 = () => {
  return (
    <footer className="bg-primary relative z-10">

      <div className="mt-12 bg-[#159ec1] py-8 lg:mt-[60px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 md:w-1/3 lg:w-1/2">
              <div className="my-1 flex justify-center md:justify-start">
                <p className="text-base text-white">
                  &copy; {new Date().getFullYear()} MiniSwap
                </p>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/3 lg:w-1/2">
              <div className="my-1">
                <div className="-mx-3 flex flex-wrap items-center justify-center md:justify-end">
                  <BottomNavLink link="/#" label="Privacy policy" />
                  <BottomNavLink link="/#" label="Legal notice" />
                  <BottomNavLink link="/#" label="Terms of service" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer7;


const BottomNavLink = ({ label, link }) => {
  return (
    <a href={link} className="px-3 text-base text-white hover:text-[#efefef]">
      {label}
    </a>
  );
};
