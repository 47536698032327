import React, { useEffect, useRef, useState } from "react";
import PriceDisplay from "../PriceFeed";
import image from "../../assets/trading.jpg"

const About6 = (
    {
        symbol,
        h2,
        paragraph
    }
) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const trigger = useRef(null);
  const popup = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!popup.current) return;
      if (
        !popupOpen ||
        popup.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setPopupOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!popupOpen || keyCode !== 27) return;
      setPopupOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      <section className="relative z-10 overflow-hidden bg-white py-20 lg:py-[110px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-1/2">
              <div className="bg-primary relative z-10 mb-12 py-12 px-5 sm:px-12 lg:mb-0 2xl:p-[70px]">
                <h2 className="mb-6 text-xl font-bold text-white">
                 {h2}
                </h2>
                <p className="mb-6 text-base leading-relaxed text-white text-opacity-70">
                    {paragraph}
                </p>
                <div className="items-end justify-between md:flex lg:block xl:flex">
                  <div className="">
                    <div className="flex items-center">
                      <span className="text-5xl font-extrabold text-white">
                        {" "}
                        <PriceDisplay
                            symbol={symbol}
                        />                  
                        {" "}
                      </span>
                    </div>
                  </div>
                </div>
                <span className="absolute top-4 right-4 -z-10">
                  <svg
                    width="33"
                    height="47"
                    viewBox="0 0 33 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16.6667"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 16.6667 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 1.99992 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 30.9999 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 16.6667 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 1.99992 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 30.9999 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 16.6667 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 16.6667 1.66683)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 1.99992 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 1.99992 1.66683)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 30.9999 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 30.9999 1.66683)"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <div className="flex h-full w-full items-center justify-center">
                <img
                  src={image}
                  alt="trading"
                  className="top-0 left-0 z-[-1] h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-black bg-opacity-80 ${
            popupOpen === true ? "block" : "hidden"
          }`}
        >
          <div
            ref={popup}
            onFocus={() => setPopupOpen(true)}
            onBlur={() => setPopupOpen(false)}
            className="mx-auto w-full max-w-[550px] bg-white"
          >
          </div>
          <button
            onClick={() => setPopupOpen(false)}
            className="text-body-color hover:bg-dark absolute top-0 right-0 flex h-20 w-20 cursor-pointer items-center justify-center bg-black"
          >
            <svg viewBox="0 0 16 15" className="h-8 w-8 fill-current">
              <path d="M3.37258 1.27L8.23258 6.13L13.0726 1.29C13.1574 1.19972 13.2596 1.12749 13.373 1.07766C13.4864 1.02783 13.6087 1.00141 13.7326 1C13.9978 1 14.2522 1.10536 14.4397 1.29289C14.6272 1.48043 14.7326 1.73478 14.7326 2C14.7349 2.1226 14.7122 2.24439 14.6657 2.35788C14.6193 2.47138 14.5502 2.57419 14.4626 2.66L9.57258 7.5L14.4626 12.39C14.6274 12.5512 14.724 12.7696 14.7326 13C14.7326 13.2652 14.6272 13.5196 14.4397 13.7071C14.2522 13.8946 13.9978 14 13.7326 14C13.6051 14.0053 13.478 13.984 13.3592 13.9375C13.2404 13.8911 13.1326 13.8204 13.0426 13.73L8.23258 8.87L3.38258 13.72C3.29809 13.8073 3.19715 13.8769 3.08559 13.925C2.97402 13.9731 2.85405 13.9986 2.73258 14C2.46737 14 2.21301 13.8946 2.02548 13.7071C1.83794 13.5196 1.73258 13.2652 1.73258 13C1.73025 12.8774 1.753 12.7556 1.79943 12.6421C1.84586 12.5286 1.91499 12.4258 2.00258 12.34L6.89258 7.5L2.00258 2.61C1.83777 2.44876 1.74112 2.23041 1.73258 2C1.73258 1.73478 1.83794 1.48043 2.02548 1.29289C2.21301 1.10536 2.46737 1 2.73258 1C2.97258 1.003 3.20258 1.1 3.37258 1.27Z" />
            </svg>
          </button>
        </div>
      </section>
    </>
  );
};

export default About6;
