import React, { useState, useEffect } from "react";
import redstone from "redstone-api";

function PriceDisplay({symbol}) {

  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const currentPrice = await redstone.getPrice(symbol);
      setPrice(currentPrice);
    }

    const interval = setInterval(fetchPrice, 1000); // update every 1 second

    return () => clearInterval(interval);

  }, [symbol]);

  if (price === null) {
    return <div>Loading price...</div>;  
  }

  return <div>${price.value}</div>;

}

export default PriceDisplay;