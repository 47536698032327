import React from "react";
import image from '../../assets/eur.svg'

const Profile3 = (
  {
    title_card,
    paragraph_card,
    button1_card,
    button1_href,
    button2_card,
    button2_href
  }
) => {
  return (
    <section>
          <div className="px-1 pt-2 pb-2">
            <div className="text-center">
              <h5 className="mb-5 text-3xl font-semibold text-black">{title_card}</h5>
            <div className="mx-auto mb-5 h-[130px] w-full max-w-[130px] overflow-hidden rounded-full">
              <img
                src={image}
                alt="EUA"
                className="h-full w-full overflow-hidden rounded-full"
              />
            </div>
            <p className="mx-auto mb-4 w-full max-w-[265px] text-sm text-body-color">
            {paragraph_card}
              </p>
              <div className="flex items-center justify-center space-x-[10px]">
                <button className="h-[34px] rounded-full border border-transparent bg-primary px-5 text-sm font-medium text-white hover:bg-opacity-90"
                  onClick={() => {
                    window.location.href = `${button1_href}`;
                  }}
                >
                  {button1_card}
                </button>
                <button className="h-[34px] rounded-full border border-transparent bg-primary px-5 text-sm font-medium text-white hover:bg-opacity-90"
                  onClick={() => {
                    window.location.href = `${button2_href}`;
                  }}
                >
                  {button2_card}
                </button>
              </div>
            </div>
          </div>
    </section>
  );
};

export default Profile3;
