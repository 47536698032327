import Hero from "./components/Hero11";
import Services from "./components/Service3";
import Cta from "./components/Cta7";
import Brand5 from "./components/BrandStyle";
import Footer7 from "./components/Footer7";
import SEO from "./components/SEO";

function Home() {
  
  return (  
  <>
    <SEO
        title={'MiniSwap | Mobile DEX Built On Opera'}
        description={'MiniSwap is mobile-first a decentralized exchange (DEX) built in Opera MiniPay that allows users to swap and trade BTC, ETH and many more cryptocurrencies directly from their Opera wallet.'}
    />
    <Hero
        header={'Decentralized Mobile Trading'}
        h1={'MiniSwap'}
        subtext={'MiniSwap is a mobile-first decentralized exchange built on Celo on integrated into Opera'}
        button={'Start Trading'}
    />
    <Services
        title={'Our features'}
        h2={'Use MiniSwap for low fees, tight spreads, optimal leverage'}
        paragraph={'We bundle the best in crypto with the best in the carbon markets'}
        service_1_title={'Trade on mobile'}
        service_1_description={'We are the first DEX built to be mobile first, offering users the ability to swap and trade directly from their phones'}
        service_2_title={'Seamless onboarding'}
        service_2_description={'Download Opera MiniPay and create a wallet using your mobile number'}
        service_3_title={'Swap and trade'}
        service_3_description={'Our exchange design allows you to do both swap and futures trading all in the same venue'}
    />
    <Cta
        header={'Trade Mobile'}
        h2={'The best in mobile trading'}
        paragraph={`MiniSwap is an innovative decentralized exchange (DEX) that has found its home on the Celo blockchain, seamlessly integrated into the Opera browser. This groundbreaking platform empowers users to effortlessly swap their preferred cryptocurrencies through the MiniPay Opera wallet, offering a secure and user-friendly experience. MiniSwap leverages the Celo blockchain's speed and cost-efficiency, ensuring swift and cost-effective transactions while maintaining the decentralization ethos that is at the core of the crypto ecosystem. With MiniSwap, users can trade their favorite digital assets with ease, fostering broader accessibility and adoption of cryptocurrencies on the Celo network while enjoying the convenience of Opera's popular web browser interface.`}
    />
    <Brand5
        h2={"We've got some amazing partners that make MiniSwap possible"}
    />
    <Cta
        header={'MiniPay Integration'}
        h2={'We are built to be mobile first'}
        paragraph={`MiniSwap is designed as a mobile-first decentralized exchange (DEX), seamlessly integrated with MiniPay by Opera. This strategic integration empowers users with the convenience of trading cryptocurrencies directly from their mobile devices. By offering mobile trading capabilities, MiniSwap provides unparalleled accessibility and flexibility, allowing users to stay connected to the market and execute trades on the go. With intuitive user interfaces tailored for mobile devices, MiniSwap enhances the trading experience, making it more interactive and user-friendly. Embracing mobile-first design principles, MiniSwap emerges as a leading DEX, catering to the evolving needs of modern traders seeking efficient and convenient trading solutions.`}
    />
    <Footer7/>
  </>

  )
}

export default Home;
