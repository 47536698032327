import React from "react";

const Stats4 = (
  {
    title,
    h2,
    paragraph
  }
) => {
  return (
    <section className="bg-white-2 py-20 lg:py-[120px]">
      <div className="mx-auto px-4 sm:container">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-7/12">
            <div className="mb-12 max-w-[560px] lg:mb-8">
              <span className="mb-4 inline-block rounded bg-primary py-1 px-4 text-lg font-semibold text-white">
                {title}
              </span>
              <h2 className='mb-4 text-3xl font-bold text-black md:text-[40px]"'>
                {h2}
              </h2>
              <p className="text-body-color text-base">
                {paragraph}
              </p>
            </div>
          </div>

          <div className="w-full px-4 lg:w-5/12">
            <div className="-mx-3 flex flex-wrap md:-mx-4">
              <StatsItem user="€800m" title="Annual trading volume" />
              <StatsItem user="40,000+" title="Registered accounts" />
              <StatsItem user="€100" title="All time price high" />
              <StatsItem user="18" title="Years in operation" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats4;

const StatsItem = ({ user, title }) => {
  return (
    <div className="w-full px-3 xs:w-1/2 md:px-4">
      <div className="group mb-6 rounded bg-white py-6 px-4 text-center shadow-three hover:bg-primary md:mb-8">
        <h4 className="mb-1 text-2xl font-bold text-black group-hover:text-white sm:text-[28px] lg:text-2xl xl:text-[28px]">
          {user}
        </h4>
        <p className="text-base font-medium text-body-color group-hover:text-white sm:text-lg lg:text-base xl:text-lg">
          {title}
        </p>
      </div>
    </div>
  );
};
