import SEO from "./components/SEO";
import Hero8 from "./components/Hero8";
import Stats4 from "./components/Stats4";
import Footer7 from "./components/Footer7";
import About6 from "./components/About6";

function TradingApp() {
  
  return (
    <>
      <SEO
        title={'Buy and Sell Crypto on a Mobile-first DEX | Miniswap'}
        description={'Miniswap is a decentralized exchange (DEX) built on Opera, facilitating crypto swaps directly in the Opera Minipay wallet. Swap directly from your mobile and get the benefit of low fees and self custody.'}
      />

      <Hero8
        title={'Miniswap: Crypto Swaps on a Mobile-First DEX'}
        h1={'Crypto Swaps the Go: Low Fees, High Convenience with Miniswap'}  
        paragraph={'Embrace the future of decentralized trading with Miniswap, a DEX designed for mobile users. Built on Opera, Miniswap enables direct swaps within the Minipay wallet, marrying low fees with a user-friendly interface. Trade ETH, BTC, and a variety of cryptocurrencies effortlessly.'}
        button={'Start Trading Now'}
        explore={'Discover More about Miniswap'}
      />

      <Stats4 
        title={'Miniswap: The Ultimate DEX for Mobile Traders'}
        h2={'Benefits of Using Miniswap'}
        paragraph={'Miniswap redefines crypto trading with its decentralized approach, offering traders low fees, direct wallet swaps, and a mobile-first design. Perfect for those who value convenience, security, and efficiency in their trading activities.'}
      />
     
      <About6
        h2={'Easily Acquire Crypto with Miniswap'}
        paragraph={"Miniswap offers a simplified way to acquire cryptocurrencies. With its focus on direct wallet integration, low fees, and mobile optimization, Miniswap provides a straightforward path to enter the world of crypto trading. Begin your journey with a platform that’s designed with the modern trader in mind."}
        symbol={'BTC'}
      />
     
     <Footer7/>
   </>
 )
}
      
export default TradingApp;
